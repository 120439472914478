import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Mikanos Homes/1v1.jpg";
import pngFile2 from "./Mikanos Homes/2v1.jpg";
import pngFile3 from "./Mikanos Homes/3v1.jpg";
import pngFile4 from "./Mikanos Homes/4v1.jpg";
import pngFile5 from "./Mikanos Homes/5v1.jpg";
import pngFile6 from "./Mikanos Homes/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Mykonos Homes</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Mykonos Homes – уникальный новый роскошный проект от Cyprus Construction. Участок расположен рядом с проектом Maldives Homes в Эсентепе, который является одной из самых популярных деревень и окружен многочисленными удобствами. Концептуальный дизайн комплекса был вдохновлен знаменитым отелем Cavo Tagoo в Греции.</div> 
        <br />
        <div>Mykonos Homes состоит из 8 блоков двухэтажных апартаментов, которые включают роскошные апартаменты и пентхаусы 1+1, 2+1, 3+1 на первом этаже. Кроме того, на первой линии расположены 8 вилл.</div>
        <br />
        <div>Отличительной особенностью является то, что абсолютно у каждой квартиры и виллы есть свой собственный бассейн или джакузи. Кроме того, есть коммунальный бассейн с подогревом, фитнес центр, сауна, ботанический сад, кинотеатр на пляже, мини гольф. Все владельцы квартир могут пользоваться инфраструктурой других комплексов данного застройщика, которые находятся по соседству. Предоставляется трансфер до супермаркета и до гольф клуба. Все дома построены с тепло- и звукоизоляцией.</div>
        <br />
        <div>Инфраструктура комплекса:</div>
        <br />
        <div>-закрытый бассейн,</div>
        <div>-спортзал,</div>
        <div>-СПА-центр,</div>
        <div>-частный пляж и кинотеатр,</div>
        <div>-водные виды спорта,</div>
        <div>-теннисный корт,</div>
        <div>-мини гольф,</div>
        <div>-комната первой помощи,</div>
        <div>-детская площадка,</div>
        <div>-ресторан,</div>
        <div>-кафе и бар,</div>
        <div>-бар у бассейна,</div>
        <div>-управляющая компания,</div>
        <div>-24/7 охрана.</div>
        <br />
        <div>100% оплата.</div>
        <br />
        <div>Дата окончания строительства: 2022 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
