import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCard";
import ProjectCard2 from "./ProjectCard2";
import ProjectCard3 from "./ProjectCard3";
import ProjectCard4 from "./ProjectCard4";
import ProjectCard5 from "./ProjectCard5";
import ProjectCard6 from "./ProjectCard6";
import ProjectCard21 from "./ProjectCard 21";
import ProjectCard22 from "./ProjectCard 22";
import ProjectCard23 from "./ProjectCard 23";
import ProjectCard24 from "./ProjectCard 24";
import ProjectCard25 from "./ProjectCard 25";
import ProjectCard26 from "./ProjectCard 26";

import ProjectCard11 from "./ProjectCard11";
import ProjectCard12 from "./ProjectCard12";
import ProjectCard13 from "./ProjectCard13";
import ProjectCard14 from "./ProjectCard14";
import ProjectCard15 from "./ProjectCard15";
import ProjectCard16 from "./ProjectCard16";

import Home2 from "./Home2";
import Home3 from "./Home3";
import Sponsors from "./Sponsors";
import Type from "./Type";
import "./home.css";
import ImageModal from "./YouTube/ImageModal1";
import LEDSign from "./LEDSign";
import "./LEDSign.css";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col className="home-header">
              <h1 style={{ paddingBottom: 5 }} className="heading">
                Добро пожаловать в{" "}
              </h1>

              <h1 className="heading-name">
                <strong className="main-name" style={{ fontWeight: "900" }}>
                  {" "}
                  White Estate
                </strong>
              </h1>
              <div style={{ padding: 0 }} className="type">
                <Type />
              </div>
              <Col className="myAvtar2">
                <ImageModal />
              </Col>
              <Col className="QR"></Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <LEDSign />
      <Container>
        <Row
          className="coolcards"
          style={{ justifyContent: "center", paddingTop: "5%" }}
        >
          <h1 data-aos="fade-right">
            <span className="primary-header">
              {" "}
              Продажи на стадии котлована{" "}
            </span>
          </h1>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              title="Sapphire Blue"
              description="Grand Sapphire Blu — это продолжение самого высокого проекта на Северном Кипре - Grand Sapphire Resort."
              description2="Цена: от 89.950£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard2
              title="K-island"
              description="Комплекс K-Island находится в районе Татлысу, спроектирован для максимального комфорта с учетом всех современных тенденций в архитектуре и дизайне."
              description2="Цена: от 157.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard3
              title="Natulux"
              description="Комплекс премиум класса Natulux - это идеальное место для роскошной жизни и инвестиционных возможностей на Северном Кипре."
              description2="Цена: от 199.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard4
              title="Kaia Residence"
              description="Расположенный в нескольких шагах от безмятежного пляжа и недалеко от местных деревень, проект Kaia предоставляет жителям и гостям острова уникальное сочетание экологичности и роскоши курорта."
              description2="Цена: от 166.750£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard5
              title="Four Seasons Life 3"
              description="Four Seasons Phase 3 расположен прямо на первой береговой линии в районе Боаз. Данный район очень привлекателен для инвестирования, цены на землю и объекты недвижимости стремительно растут."
              description2="Цена: от 130.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard6
              title="Querencia"
              description="Проект Querencia будет расположен в 400 метрах от живописного пляжа Средиземного моря. Он станет великолепным дополнением побережья, предлагая резидентам комплекса уникальные видовые апартаменты в сочетании с прекрасной инфраструктурой."
              description2="Цена: от 134.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard21
              title="Alpine Hills"
              description="Предоставляем вашему вниманию новый эксклюзивный бутик-проект Alpine Hills, расположенный в Татлысу и окруженный прекрасным видом на море и горы."
              description2="Цена: от 95.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard22
              title="Orchard 3"
              description="Orchard - это комплекс, расположенный в Йенибоазичи и подчеркивающий спокойный образ жизни в окружении зелени, в нескольких километрах от Фамагусты."
              description2="Цена: от 104.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard23
              title="Dolce Vita"
              description="Масштабный малоэтажный комплекс бизнес класса на 408 юнитов Dolce Vita находится вблизи пляжа Лонг Бич и предлагает своим жителям территорию для жизни с максимальной внутренней инфраструктурой."
              description2="Цена: от 115.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard24
              title="La Joya Perla"
              description="Комплекс на 384 юнита La Joya Perla возводится дальше высотных домов и плотной застройки локации Искеле (Лонг Бич)."
              description2="Цена: от 109.900£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard25
              title="Salamis Holiday Homes"
              description="Жилой комплекс на 144 юнита Salamis Holiday Homes расположен в в пешей доступности от начальной школы."
              description2="Цена: от 95.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard26
              title="La Casalia"
              description="Эксклюзивный комплекс La Casalia будет находится на 260 донюмах земли (340 000 м2)."
              description2="Цена: от 182.000£."
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ justifyContent: "center", paddingTop: "5%" }}>
          <h1 data-aos="fade-right">
            <span className="primary-header"> Продажи готовых объектов </span>
          </h1>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard11
              title="Four Seasons Life 1"
              description="Four Seasons Life новый жилой комплекс современных, красивых апартаментов и вилл, прямо на прекрасном песчаном пляже в регионе Боаз."
              description2="Цена: от 300.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard12
              title="Тhalassa Beach Resort"
              description="Комплекс Thalassa Beach Resort находится на первой береговой линии в регионе Бафра и состоит из студий, элитных апартаментов."
              description2="Цена: от 150.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard13
              title="Courtyard Long Beach"
              description="Если вы поклонник отельной жизни, то жилой комплекс апартаментов класса люкс Courtyard Long Beach - отлично подойдет именно вам!"
              description2="Цена: от 100.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard14
              title="Maldives Homes"
              description="Комплекс Maldives Homes состоит из уникальных вилл и роскошных апартаментов, расположенных на востоке города Кирении в Эсентепе."
              description2="Цена: от 425.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard15
              title="Panorama"
              description="Panorama - новый комплекс в самом сердце района Искеле в 150 метрах от пляжа Лонг Бич. Проект включает два 12-этажных блока, состоящих из 111 роскошных апартаментов."
              description2="Цена: от 180.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard16
              title="Mykonos Homes"
              description="Вдохновлённые греческим островом Миконос, команда архитекторов спроектировала шикарный проект, который построили в районе Бахчели прямо на побережье Средиземного моря."
              description2="Цена: от 400.000£."
            />
          </Col>
        </Row>
      </Container>
      <Container className="home2">
        <Home2 />
        <h1 style={{ paddingTop: "5%", paddingBottom: "2%" }}>
          <span className="primary-header"> Наши партнеры </span>
        </h1>
        <div className="sponsors" data-aos="fade-up">
          <Sponsors />
        </div>
        <div className="home3" style={{ paddingTop: "5%" }}>
          <Home3 />
        </div>
        {/* <ScrollToTop /> */}
      </Container>
    </section>
  );
}

export default Home;
