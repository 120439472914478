import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./4S/1v1.jpg";
import pngFile2 from "./4S/2v1.jpg";
import pngFile3 from "./4S/3v1.jpg";
import pngFile4 from "./4S/4v1.jpg";
import pngFile5 from "./4S/5v1.jpg";
import pngFile6 from "./4S/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Four Seasons Life 3</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Совершенно новый роскошный проект расположен в нескольких минутах ходьбы от песчаного пляжа в районе Богаз региона Искеле на Северном Кипре. Идеальное расположение для тех, кто хочет быть ближе к природе, но недалеко от города. Удобное расположение и сочетание богатой инфраструктуры комплекса гарантируют вам отличную инвестиционную возможность на Северном Кипре.</div>
        <br />
        <div>Разнообразие удобств и услуг комплекса обеспечивает широкий спектр развлечений для жителей всех возрастов. Этот проект подходит для постоянного проживания или сезонного отдыха.</div>
        <br />
        <div>Four Seasons Life 3 включает в себя 738 резиденций просторных квартир современного дизайна. Доступны апартаменты-студии с отдельной террасой на крыше, апартаменты 1+1 с садом и мансардным садом, а также апартаменты 2+1 с садом или террасой на крыше.</div>
        <br />
        <div>Инфраструктура: </div>
        <br />
        <div>удобства для людей с ограниченными возможностями, 
открытые и крытые бассейны, 
детский бассейн, 
аквапарк, 
велосипедные и пешеходные дорожки, 
фитнес-центр, 
SPA и массажные салоны, 
площадки для тенниса, баскетбола и волейбола,
парикмахерская, 
парковка для инвалидов, 
круглосуточная охрана, 
автостоянка. </div>
<br />
<div>Каждая квартира оснащена всем необходимым для вашей удобной жизни:</div>
<br />
<div>-центральный генератор,</div>
<div>-американская кухня, </div>
<div>-встроенные шкафы,</div>
<div>-система кондиционирования воздуха,</div>
<div>-стальные входные двери,</div>
<div>-остекление ПВХ,</div>
<div>-центральная спутниковая и интернет-системы,</div>
<div>-видеодомофон.</div>
<br />
<div>План оплаты: </div>
<br />
<div>-5% от стоимости недвижимости,</div>
<div>-35% - первоначальный взнос,</div>
<div>-60% беспроцентная рассрочка до окончания строительства.</div>
<br />
<div>Срок сдачи: январь 2026 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
