import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Orchard3/1v1.jpg";
import pngFile2 from "./Orchard3/2v1.jpg";
import pngFile3 from "./Orchard3/3v1.jpg";
import pngFile4 from "./Orchard3/4v1.jpg";
import pngFile5 from "./Orchard3/5v1.jpg";
import pngFile6 from "./Orchard3/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Orchard 3</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Orchard - это комплекс, расположенный в Йенибоазичи и подчеркивающий спокойный образ жизни в окружении зелени, в нескольких километрах от Фамагусты. В центре проекта есть несколько общественных бассейнов и игровых площадок, а также красиво благоустроенные улицы и парки. Также есть крытый бассейн, сауна, ресторан, тренажерный зал и супермаркет.</div> 
        <br />
        <div>3 очередь жилого комплекса "Orchard 3" класса люкс на 30 юнитов, расположен рядом с пляжем, большим парком и частной школы Near East в Йенибоазичи.</div>
        <br />
        <div>В 10-ти минутах езды доступна вся городская инфраструктура: университет, банки и обменные пункты, магазины, торговый и развлекательные центры, рестораны и кафе и тд. Для любителей прогулок по историческим местам доступны к посещению древний город Salamis, старый город Фамагусты и заброшенная Вароша.</div>
        <br />
        <div>Orchard 3 занимает территорию площадью 20 000 кв. м, включает комфортабельные апартаменты и таунхаусы различной площади, а также отдельно стоящие виллы с тремя и четырьмя спальнями с собственным участком и бассейном. Вся недвижимость продается с чистовой отделкой, полностью оборудованными ванными комнатами, кухнями со встроенными гарнитурами, шкафами для хранения в спальнях.</div>
        <br />
        <div>Инфраструктура комплекса:</div>
        <div>-закрытая, благоустроенная территория под видеонаблюдением с большим количеством цветов и фруктовых деревьев,</div>
        <div>-фитнес-центр и спа центр,</div>
        <div>-крытый и открытый бассейны,</div>
        <div>-ресторан и бар и зона для барбекю,</div>
        <div>-супермаркет,</div>
        <div>-детская площадка,</div>
        <div>-теннисный корт,</div>
        <div>-игровая зона со столами для бильярда и пинг-понга.</div>
        <br />
        <div>Основные характеристики: </div>
        <br />
        <div>-просторная гостиная с большим балконом,</div>
        <div>-кухня со встроенной мебелью высокого качества,</div>
        <div>-покрытие пола керамической плиткой,</div>
        <div>-спальня со встроенным шкафом,</div>
        <div>-полностью оборудованная ванная комната,</div>
        <div>-высококачественная керамическая плитка на полу и на стенах в ванной комнате,</div>
        <div>-инфраструктура для установки кондиционеров воздуха,</div>
        <div>-инфраструктура под интернет и телевидение,</div>
        <div>-панорамные окна с двойными стеклопакетами,</div>
        <div>-стеклянные перила на балконах.</div>
        <br/>
        <div>Своя управляющая компания обеспечивает контроль над сохранностью актива в период сдачи недвижимости в аренду и предоставляют отчеты о загрузке. Особое внимание застройщик уделил ландшафтному дизайну и планирует создать настоящий оазис с большим количеством цветов и фруктовых деревьев.</div>
        <br />
        <div>План оплаты:</div>
        <br />
        <div>-5000 депозит,</div>
        <div>-35% первоначальный взнос,</div>
        <div>-60% - беспроцентная рассрочка до получения ключей.</div>
        <br />
        <div>Срок сдачи: декабрь 2025 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
