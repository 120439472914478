import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Kai/1v1.jpg";
import pngFile2 from "./Kai/2v1.jpg";
import pngFile3 from "./Kai/3v1.jpg";
import pngFile4 from "./Kai/4v1.jpg";
import pngFile5 from "./Kai/5v1.jpg";
import pngFile6 from "./Kai/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Kaia Residence</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>KAIA—это больше, чем просто жилищный проект—это комплексный образ жизни, тщательно разработанный для жителей.</div>
        <br />
        <div>Прибрежный комплекс предлагает разнообразные удобства для удовлетворения
всех аспектов вашей жизни. Насладитесь изысканной кухней в ресторане или
расслабьтесь в баре у бассейна. Найдите возможность для продуктивной работы
и сотрудничества в коворкингах или насладитесь свежим воздухом в зеленых
садах. Посетите песчаный пляж, полюбуйтесь видом с живописной платформы
или прогуляйтесь по пешеходным и велосипедным дорожкам. Уделите
внимание своему здоровью в студии йоги и фитнеса, спа, сауне и
гидромассажных ваннах, а также позвольте своим детям насладиться
современными игровыми комнатами и площадками. </div>
        <br />
        <div>Он представляет собой идеальное сочетание отдельно стоящих домов, вилл, triplex состоящих из апартаментов 1+1, 2+1, 3+1.</div>
        <br />
        <div>Расположение комплекса.</div>
        <br />
        <div>Находится среди захватывающих дух пейзажей Северного Кипра.
В радиусе 60 км от комплекса вы найдете множество интересных
достопримечательностей, отвечающих самым разным интересам. Начиная от
удаленности аэропорта и очарования местных городов до исторических
сокровищ, таких как замки и древние места, а также привлекательности мест для
пеших прогулок. Расположенный среди нетронутой природы, это место также
славится высоким качеством местных продуктов, в частности, оливками и оливковым маслом.</div>
        <br />
        <div>Расположение объектов.</div>
        <br />
        <div>Лофты с тремя спальнями — это
воплощение роскоши, расположенные
прямо на берегу нетронутой
набережной. Эти двухэтажные виллы
расположены на обширных участках у
моря, а жилые помещения имеют
потолки высотой 4,6 метра.</div>
        <br />
        <div>Дома-триплекс, расположенные в
третьем ряду от моря, представляют
собой образец изысканного дизайна.
Эти роскошные дома-близнецы с
двумя спальнями занимают три этажа,
обеспечивая приватное уединение на
каждом уровне.</div>
         <br />
         <div>Courtyard с тремя спальнями,
расположенные во втором ряду от
моря, предлагают гибкость и
позволяют изменять функции третьей
спальни. Ведь его можно
переоборудовать под игровую комнату
или домашний офис.</div>
         <br />
         <div>Attached houses, 1+1 и 2+1,
расположены со второго по четвертый
ряды от моря. Квартиры на первом
этаже восхищают собственными садами,
а апартаменты на втором имеют
террасы на крыше с прекрасным видом
на бассейн или море.</div>
         <br />
         <div>План оплаты: </div>
         <br />
         <div>-40% первоначального взноса оплачивается при
подписании контракта,</div>
<div>-60%выплачиваются ежемесячными или
ежеквартальными платежами до момента передачи
ключей. </div>
<br />
<div>Срок сдачи: май 2025 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
