import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Тhalassa Beach Resort/1v1.jpg";
import pngFile2 from "./Тhalassa Beach Resort/2v1.jpg";
import pngFile3 from "./Тhalassa Beach Resort/3v1.jpg";
import pngFile4 from "./Тhalassa Beach Resort/4v1.jpg";
import pngFile5 from "./Тhalassa Beach Resort/5v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Тhalassa Beach Resort</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Комплекс Thalassa Beach Resort находится на первой береговой линии в регионе Бафра и состоит из студий, элитных апартаментов с 1, 2 и 3 спальнями и пентхаусов с частными террасами на крыше, с которых открываются захватывающие виды на Средиземное море. Планировка каждой квартиры сочетает комфорт и современный дизайн. В квартирах на первом этаже предусмотрены собственные участки с частными бассейнами. В квартирах второго и третьего этажа есть просторные террасы, идеально подходящие для завтраков и ужинов на свежем воздухе. В пентхаусах – большие солнечные террасы на крыше с панорамными видами окрестностей.</div> 
        <br />
        <div>Расположение комплекса.</div>
        <br />
        <div>Вблизи комплекса – туристический центр с магазинами, местами отдыха и развлечений. В 40 минутах езды от комплекса есть чемпионское гольф-поле. В нескольких минутах езды находится рыбацкий посёлок Богаз, с его рыбными ресторанами и небольшой, но прекрасной гаванью.</div>
        <br />
        <div>Инфраструктура:</div>
        <br />
        <div>-общественный бассейн и аквапарк,</div>
        <div>-детская площадка,</div>
        <div>-песчаный пляж,</div>
        <div>-спортивные площадки,</div>
        <div>-игровая комната,</div>
        <div>-ресторан,</div>
        <div>-фитнес центр и СПА.</div>
        <br />
        <div>6 причин инвестировать в комплекс Thalassa.</div>
        <br />
        <div>БАФРА: будущий туристический район со значительными инвестициями и развитием ВИП туризма.</div>
        <br />
        <div>МЕЖДУНАРОДНО-ПРИЗНАННЫЕ ДОКУМЕНТЫ О ПРАВЕ СОБСТВЕННОСТИ: комплекс имеет международные титулы земли, что обеспечивает безопасность и душевное спокойствие покупателям.</div>
        <br />
        <div>ПРЕКРАСНОЕ РАСПОЛОЖЕНИЕ НА БЕРЕГУ МОРЯ: Thalassa расположена на берегу прекрасного песчаного пляжа.</div>
        <br />
        <div>ВЫСОКИЙ АРЕНДНЫЙ ПОТЕНЦИАЛ: покупка недвижимости в Thalassa дает вам возможность зарабатывать на своих инвестициях.</div>
        <br />
        <div>ПЕРВОКЛАССНЫЕ УСЛУГИ И
ЭКСКЛЮЗИВНЫЕ ПРЕИМУЩЕСТВА ДЛЯ РЕЗИДЕНТОВ: инфраструктура комплекса постоянно
обновляется и развивается.</div>
        <br />
        <div>2 варианта оплаты:</div>
        <br />
        <div>-100% оплата, </div>
        <div>-60% - первоначальный взнос и 40% - в течение 18 месяцев.</div>
        <br />
        <div>Дата окончания строительства: 2018 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
