import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./La Joya Perla/1v1.jpg";
import pngFile2 from "./La Joya Perla/2v1.jpg";
import pngFile3 from "./La Joya Perla/3v1.jpg";
import pngFile4 from "./La Joya Perla/4v1.jpg";
import pngFile5 from "./La Joya Perla/5v1.jpg";
import pngFile6 from "./La Joya Perla/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>La Joya Perla</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Комплекс на 384 юнита La Joya Perla возводится дальше высотных домов и плотной застройки локации Искеле (Лонг Бич). Район активно застраивается. До самого узнаваемого пляжа Long beach (Лонг Бич) 3.8 км.</div> 
        <br />
        <div>Для любителей пеших прогулок и бега есть оборудованная набережная вдоль пляжа. Детские и спортивные площадки для детей разный возрастных групп, а также площадка для МГН. Аптека, магазины, кафе, рестораны и зоны для пикника, Бич клаб Pera и Луна-парк - все это в доступности жителей.</div>
        <br />
        <div>Этот проект расположен в живописном месте, с видом на Средиземное море, и предоставляет своим жителям доступ к пляжному клубу La Joya Beach Club, где они могут наслаждаться разнообразными развлечениями и удобствами. В комплексе всего 384 роскошные резиденции, и каждая из них окружена изысканным бассейном с уникальным баром в центре, создавая неповторимую атмосферу релаксации и удовольствия.</div>
        <br />
        <div>Дома оборудованы системой «умный дом», которая позволяет управлять отоплением, охлаждением и освещением с помощью мобильного телефона. Благодаря камерам дверного звонка и системе безопасности, вы можете чувствовать себя защищенными.
        La Joya Perla также предлагает прямой доступ к пляжному клубу La Joya Beach Club, где вы сможете наслаждаться ресторанами премиум-класса, барами, спа-центрами и многими другими удобствами. Расположенный в регионе Лонг-Бич Искеле, это идеальное место для инвестиций в недвижимость на Северном Кипре благодаря своему прекрасному расположению и доступу к социальным объектам.</div>
        <br />
        <div>Особенности недвижимости:</div>
        <br />
        <div>-глянцевое покрытие для кухни в 3-х цветовых вариантах,</div>
        <div>-алюминий с двойным остеклением,</div>
        <div>-подоконник из натурального мрамора или травертина,</div>
        <div>-вариант паркета или керамики с 3 вариантами цвета,</div>
        <div>-американские панельные двери,</div>
        <div>-стальная лестница с деревянной ступенькой,</div>
        <div>-полы с подогревом в ванных комнатах,</div>
        <div>-центральное отопление и охлаждение в доме,</div>
        <div>-системы «умного дома» (управление освещением и отоплением/ охлаждением).</div>
        <br />
        <div>Инфраструктура:</div>
        <br />
        <div>-открытый плавательный бассейн,</div>
        <div>-аквапарк,</div>
        <div>-крытый плавательный бассейн,</div>
        <div>-игровая площадка,</div>
        <div>-баскетбольная площадка,</div>
        <div>-мини-маркет,</div>
        <div>-СПА-центр и тренажерный зал,</div>
        <div>-парикмахер-унисекс,</div>
        <div>-ресторан,</div>
        <div>-бар у бассейна,</div>
        <div>-детский клуб,</div>
        <div>-открытый амфитеатр / кинотеатр,</div>
        <div>-центральный генератор,</div>
        <div>-центральный спутник,</div>
        <div>-центральный интернет.</div>
        <br />
        <div>План оплаты:</div>
        <br />
        <div>-5000 фунтов депозит,</div>
        <div>-45% - до получения ключей,</div>
        <div>-20% - беспроцентная рассрочка после получения ключей.</div>
        <br />
        <div>Срок сдачи: ноябрь 2026 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
