import React from "react";
import Card from "react-bootstrap/Card";
import ImageModal24 from "./Techstack1/ImageModal24";
import pngfile from "./Techstack1/La Joya Perla/1v2.jpg";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={pngfile} alt="card-img" />
      <Card.Body>
        <Card.Title style={{ paddingBottom: "15px", fontSize: "25px" }}>
          {props.title}
        </Card.Title>
        <Card.Text
          style={{ textAlign: "left", paddingBottom: "25px", padding: "15px" }}
        >
          <div style={{ fontWeight: "700", paddingBottom: "25px" }}>
            {props.description2}
          </div>
          {props.description}
        </Card.Text>
      </Card.Body>
      <div style={{ paddingBottom: "15px" }}>
        <ImageModal24 />
      </div>
    </Card>
  );
}
export default ProjectCards;
