import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Kisland/1v1.jpg";
import pngFile2 from "./Kisland/2v1.jpg";
import pngFile3 from "./Kisland/3v1.jpg";
import pngFile4 from "./Kisland/4v1.jpg";
import pngFile5 from "./Kisland/5v1.jpg";
import pngFile6 from "./Kisland/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>K-island</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Комплекс K-Island расположен в одной из самых перспективных локаций. Рядом строят объекты бизнес и премиум сегмента. Захватывающие виды на горы и море не оставят равнодушными гостей и жителей комплекса. В проекте будут представлены шесть уникальных и индивидуально вдохновленных островов, каждый со своей отличительной темой и колоритом, с потрясающим видом на лагуну и ландшафтные сады. Комплекс включает в себя огромное разнообразие типов недвижимости: от апартаментов-студий до частных вилл с бассейнами.</div>
        <br />
        <div>В доступности 5ти минут на автомобиле Татлысу, где есть начальная школа, рестораны, кафе, бары, парки, спортивные и детские площадки, магазины, рынок, почтовое отделение, полицейский участок, аптека, муниципальный госпиталь.</div>
        <br />
        <div>Внутренняя инфраструктура закроет многие потребности резидентов:</div>
        <br />
        <div>-закрытая, благоустроенная территория,</div>
        <div>-лаундж для резидентов и гостей с библиотекой, камином, комнатой для проведения праздников и торжеств,</div>
        <div>-коворкинг,</div>
        <div>-ресторан и кафе,</div>
        <div>-фитнес клуб и спа-комплекс,</div>
        <div>-оздоровительный центр,</div>
        <div>-теннисный и баскетбольный корт,</div>
        <div>-территория для занятий йогой,</div>
        <div>-детский клуб и детская площадка.</div>
        <br />
        <div>Своя управляющая компания обеспечивает контроль над сохранностью актива в период сдачи недвижимости в аренду и предоставляют отчеты о загрузке.</div>
        <br />
        <div>Приобретая недвижимость в эксклюзивном проекте K-Islands и оплачивая полную стоимость сразу, вы автоматически получаете скидку 5% от исходной стоимости! Это ваш шанс стать обладателем выдающейся недвижимости по еще более привлекательной цене.</div>
        <br />
        <div>План оплаты: </div>
        <br />
        <div>-депозит 5% от стоимости недвижимости,</div>
        <div>-35% первоначальный взнос,</div>
        <div>-беспроцентная рассрочка 60% до конца строительства.</div>
        <br />
        <div>Срок сдачи: июнь 2026 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
