import React, { useState } from "react";
import { Modal, Image } from "react-bootstrap";
import pngFile from "./6v1.jpg";
import openImageIcon from "./6v2.jpg";

function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a onClick={handleShow}>
        <Image
          src={openImageIcon}
          fluid
          alt="White Estate Agency"
          style={{ cursor: "pointer" }}
        />
      </a>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        style={{ paddingTop: "7em" }}
      >
        <Image src={pngFile} alt="White Estate" fluid />
      </Modal>
    </>
  );
}

export default ImageModal;
