import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Courtyard Long Beach/1v1.jpg";
import pngFile2 from "./Courtyard Long Beach/2v1.jpg";
import pngFile3 from "./Courtyard Long Beach/3v1.jpg";
import pngFile4 from "./Courtyard Long Beach/4v1.jpg";
import pngFile5 from "./Courtyard Long Beach/5v1.jpg";
import pngFile6 from "./Courtyard Long Beach/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Courtyard Long Beach</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Курортный отель, построенный в 2021 году на площади 8000 м2, расположен на Лонг Бич. Он сочетает в себе идеальное географическое положение, уникальную архитектуру, роскошь и комфорт.</div> 
        <br />
        <div>30 марта 2019 года Courtyard получил престижную премию на Северном Кипре Property NC Awards как “Лучший новый проект", а также как "Лучший инвестиционный проект».</div>
        <br />
        <div>Удобство комплекса Courtyard кроется в его названии. С английского Courtyard переводится как "Двор", то есть вы можете наслаждаться всеми услугами ЖК на частной территории, без надобности ходить далеко и не выходя из «двора». Если вы поклонник отельной жизни, то жилой комплекс апартаментов класса люкс Courtyard Long Beach - для вас! Он находится в 600 метрах от моря в регионе Искеле. Пляж песчаный, государственный, оборудован тренажёрами для детей и взрослых, детской площадкой, кафе, барами, беговыми и велосипедными дорожками. В проекте представлены квартиры следующих планировок: студия, 1+1, 2+1, 3+1.</div>
        <br />
        <div>Инфраструктура:</div>
        <br />
        <div>-открытый олимпийский бассейн,</div>
        <div>-крытый бассейн,</div>
        <div>-фитнес-центр и СПА,</div>
        <div>-детский бассейн,</div>
        <div>-мини-клуб для детей,</div>
        <div>-зеленая зона,</div>
        <div>-супермаркет,</div>
        <div>-аренда автомобилей,</div>
        <div>-игровые зоны для детей,</div>
        <div>-бар и ресторан,</div>
        <div>-прачечная,</div>
        <div>-охрана, видеонаблюдение,</div>
        <div>-парковка и другое.</div>
        <br />
        <div>100% оплата. </div>
        <br />
        <div>Дата окончания строительства: 2021 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
