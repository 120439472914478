import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  BsTelegram,
  BsPlayBtnFill,
  BsWhatsapp,
  BsTelephone,
  BsInstagram,
} from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { FaRegPaperPlane } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid id="" style={{ textAlign: "center" }}>
      <Container>
        <Row>
          <Col md={12} className="" style={{ marginTop: "4%" }}>
            <h1>
              <span className="primary-header">Северный Кипр</span>
            </h1>
            <p
              className="home-about-body"
              data-aos="fade-up"
              style={{ textAlign: "left" }}
            >
              <div>
                Северный Кипр, или Турецкая Республика Северного Кипра находится
                в северо-восточной части Средиземного моря. Именно здесь
                зародилась мировая цивилизация, и чтобы в этом убедиться, нужно
                хотя бы раз побывать здесь. Климат на острове уникальный, почти
                райский, и это одно из основных достоинств Кипра над другими
                курортными странами.
              </div>
              <br />
              <div>
                Самые привлекательные на рынке недвижимости - это Фамагуста,
                Искеле-Лонг Бич, Йени-Боазичи, Боаз, Татлысу, Эсентепе, Гирне,
                Асенджак, Лапта, Никосия. Здесь огромное количество новостроек,
                самые лучшие золотистые пляжи и различные виды жилья с видом на
                море со стоимостью от 1000 до 4000 евро за м².
              </div>
              <br />
              <div>
                Купить недвижимость в этой части острова можно не только для
                проживания с семьей, но и для сдачи в аренду. Благодаря изобилию
                солнечных дней в году (340) и развитию инфраструктуры поток
                туристов стабильно растет.
              </div>
            </p>
          </Col>
          {/* <Col md={4} className="myAvtar">
            <Tilt>
              <img
                data-aos="fade-left"
                src={myImg}
                className="img-fluid"
                alt="avatar"
              />
            </Tilt>
          </Col> */}
        </Row>
        <Row>
          <Col
            md={12}
            className="home-about-social"
            style={{ paddingTop: 70, paddingBottom: 50 }}
          >
            <h1 data-aos="fade-right">
              <span className="primary-header">Наши контакты</span>
            </h1>
            <p data-aos="fade-left">свяжитесь с нами</p>
            <ul className="home-about-social-links" data-aos="fade-up">
              <li className="social-icons">
                <a
                  href="https://t.me/whiteagencyestate"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="mail"
                >
                  <AiOutlineMail />
                </a>
              </li>
              {/* <li className="social-icons">
                <a
                  href="https://www.youtube.com/@white_estate"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="youtube"
                >
                  <BsPlayBtnFill />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/whiteestate_agency/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="instagram"
                >
                  <BsInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="phone"
                >
                  <BsTelephone />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
