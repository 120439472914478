import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Alpine Hills/1v1.jpg";
import pngFile2 from "./Alpine Hills/2v1.jpg";
import pngFile3 from "./Alpine Hills/3v1.jpg";


import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Alpine Hills</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Предоставляем вашему вниманию новый эксклюзивный бутик-проект Alpine Hills, расположенный в Татлысу. Окруженный прекрасным видом на море и горы, он находится в одном из самых зеленых районов Северного Кипра. Это идеальное место для тех, кто ищет спокойный и комфортный образ жизни. Здесь есть все, чтобы вы проводили время в свое удовольствие.</div> 
        <br />
        <div>Инфраструктура:</div>
        <br />
        <div>-огороженная территория, окруженная оливковой рощей,</div>
        <div>-2 общих бассейна,</div>
        <div>-детская игровая площадка,</div>
        <div>-тренажерный зал,</div>
        <div>-сауна,</div>
        <div>-кафе и витаминный бар,</div>
        <div>-маркет,</div>
        <div>-генератор,</div>
        <div>-резервуар с питьевой водой,</div>
        <div>-мини-гольф.</div>
        <br />
        <div>До моря - 500 метров.</div>
        <div>До аэропорта Эрджан - 35 км.</div>
        <div>До Кирении - 24 км.</div>
        <br />
        <div>Комплекс состоит из:</div>
        <br />
        <div>-4 вилл, 6 таунхаусов (3+1 и 2+1), все виллы и таунхаусы имеют свои бассейны, верхние террасы оборудованы зоной отдыха и барбекю и перголой, свои парковочные места и зеленые зоны по периметру,</div>
        <br />
        <div>-8 студий,</div>
        <div>-12 апартаментов 1+1,</div>
        <div>-8 апартаментов 1+1 лофт,</div>
        <div>-12 апартаментов 2+1 лофт.</div>
        <br />
        <div>В каждой квартире отделка премиум класса, смесители Hansgrohe, теплые полы, стандартное кондиционирование.</div>
        <br />
        <div>План оплаты:</div>
        <br />
        <div>-5000 фунтов - депозит,</div>
        <div>-35% первоначальный взнос в течение 1 месяца,</div>
        <div>-25% до получения ключей,</div>
        <div>-35% - беспроцентная рассрочка на 1,5-2 года.</div>
        <br />
        <div>Цена студий начинается от 95.000 фунтов.</div>
        <br />
        <div>Все условия оплаты оговариваются индивидуально. Застройщик готов идти на компромиссы и учитывает все пожелания клиентов.</div>
        <br />
        <div>При покупке апартаментов 2+1, виллы или таунхауса мы дарим ценный подарок, который вам однозначно пригодится! А что за подарок вы можете узнать у наших менеджеров.</div>
        <br />
        <div>Друзья, такого еще не было, чтобы проект был наполовину построен, а цены как на котловане - низкие! Кстати, стоимость студий однозначно радует, так как в этом регионе самая низкая цена за студию - 150.000 фунтов. Поэтому поспешите сделать удачное вложение в свое будущее!</div>
        <br />
        <div>Срок сдачи: май-август 2024 года.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
