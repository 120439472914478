import React from "react";
import { Col, Row } from "react-bootstrap";
import ImageModal1 from "../Techstack2/ImageModal1";
import ImageModal2 from "../Techstack2/ImageModal2";
import ImageModal3 from "../Techstack2/ImageModal3";
import ImageModal4 from "../Techstack2/ImageModal4";
import ImageModal5 from "../Techstack2/ImageModal5";
import ImageModal6 from "../Techstack2/ImageModal6";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={10} md={3} className="tech-icons">
        <ImageModal1 />
      </Col>
      <Col xs={10} md={3} className="tech-icons">
        <ImageModal2 />
      </Col>
      <Col xs={10} md={3} className="tech-icons">
        <ImageModal3 />
      </Col>
      <Col xs={10} md={3} className="tech-icons">
        <ImageModal4 />
      </Col>
      <Col xs={10} md={3} className="tech-icons">
        <ImageModal5 />
      </Col>
      <Col xs={10} md={3} className="tech-icons">
        <ImageModal6 />
      </Col>
    </Row>
  );
}

export default Techstack;
