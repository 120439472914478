import React from "react";
import Card from "react-bootstrap/Card";
import { AiOutlineArrowRight } from "react-icons/ai";

function AboutCard() {
  return (
    <Card className="quote-card-view ">
      <Card.Body style={{ paddingBottom: "50px", paddingTop: "50px" }}>
        <blockquote className="blockquote mb-0" data-aos="zoom-in">
          <ul>
            <li className="about-activity">
              <AiOutlineArrowRight /> Недвижимость на стадии котлована
              (off-plan):
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "10px",
                  paddingLeft: "25px",
                }}
              >
                Доходность от 20% годовых. Закрытые продажи, надежные
                застройщики.
              </p>
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Вторичная недвижимость:
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "10px",
                  paddingLeft: "25px",
                }}
              >
                Доходность от аренды минимум от 10% годовых. Готовый бизнес.
              </p>
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Земельный участок:
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "10px",
                  paddingLeft: "25px",
                }}
              >
                Доходность х2 и реализация своих проектов.
              </p>
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Оздоровительные SPA-комплексы:
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "10px",
                  paddingLeft: "25px",
                }}
              >
                Доход от продажи и аренды + отельный менеджмент/услуги,
                таймшеринг.
              </p>
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
