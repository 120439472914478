import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillMail } from "react-icons/ai";
import "./footer.css";
import {
  BsTelegram,
  BsPlayBtnFill,
  BsWhatsapp,
  BsTelephone,
  BsInstagram,
} from "react-icons/bs";

function Footer() {
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright"></Col>
        <Col md="4" className="footer-copywright">
          <span>White Estate © 2024</span>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                style={{ color: `var(--clr-primary)` }}
                className="telegram"
                href="https://t.me/whiteagencyestate"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="telegram"
              >
                <BsTelegram />
              </a>
            </li>
            <li className="social-icons">
              <a
                style={{ color: `var(--clr-primary)` }}
                className="email"
                href="mailto:kseniya@whiteestate.co"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="email"
              >
                <AiFillMail />
              </a>
            </li>
            <li className="social-icons">
              <a
                style={{ color: `var(--clr-primary)` }}
                className="youtube"
                href="https://www.youtube.com/@white_estate"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="youtube"
              >
                <BsPlayBtnFill />
              </a>
            </li>
            <li className="social-icons">
              <a
                style={{ color: `var(--clr-primary)` }}
                className="whatsapp"
                href="https://api.whatsapp.com/send?phone=905338651575"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="whatsapp"
              >
                <BsWhatsapp />
              </a>
            </li>
            <li className="social-icons">
              <a
                style={{ color: `var(--clr-primary)` }}
                className="instagram"
                href="https://www.instagram.com/whiteestate_agency/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="instagram"
              >
                <BsInstagram />
              </a>
            </li>
            <li className="social-icons">
              <a
                style={{ color: `var(--clr-primary)` }}
                className="phone"
                href="tel:+905338651575"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="phone"
              >
                <BsTelephone />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
