import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Maldives Homes/1v1.jpg";
import pngFile2 from "./Maldives Homes/2v1.jpg";
import pngFile3 from "./Maldives Homes/3v1.jpg";
import pngFile4 from "./Maldives Homes/4v1.jpg";
import pngFile5 from "./Maldives Homes/5v1.jpg";
import pngFile6 from "./Maldives Homes/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Maldives Homes</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Maldives Homes состоят из уникальных вилл и роскошных апартаментов, расположенных на востоке Кирении в Эсентепе.</div> 
        <br />
        <div>Эсентепе, известная как самая популярная деревня, окружена многочисленными удобствами, что делает этот район восходящей звездой для инвестиций в недвижимость на Северном Кипре.</div>
        <br />
        <div>Экзотические виллы и апартаменты на Мальдивах имеют великолепный и беспрепятственный вид на север и синее Средиземное море. Архитектура и высочайший уровень отделки – это именно то, что предлагает застройщик Cyprus Construction. Участок находится всего в 20 метрах от моря: с великолепной лагуной, которая используется как собственный частный пляж.</div>
        <br />
        <div>Maldives Homes состоит из 11 вилл (2 + 1 и 3 + 1) и 64 апартаментов (1 + 1, 2 + 1 и 3 + 1). </div>
        <br />
        <div>Общие характеристики и материалы, использованные при строительстве, следующие:</div>
        <br />
        <div>тепло-и звукоизоляция,
энергосберегающие кирпичи,
окна с двойным остеклением,
холодостойкая черная рама из алюминия,
современные глянцевые кухонные гарнитуры с системами soft close и гранитными столешницами,
идеальная стандартная сантехника,
техника премиум класса для ванной и кухни,
выбор крупногабаритного керамогранита на кухне / в ванных комнатах,
5-летняя гарантия на строительство,
ухоженные сады: полностью спроектированы и озеленены,
полное кондиционирование,
роскошные душевая и ванные комнаты в номере,
выбор высококачественных встроенных шкафов с системой мягкого закрытия,
терраса со встроенным освещением и розетками,
каменные стены перед участком и дорожки,
парковка во дворе,
система центрального генератора.</div>
<br />
<div>Инфраструктура:</div>
<br />
<div>-роскошный бассейн,</div>
<div>-собственный пляж,</div>
<div>-собственный пляж,</div>
<div>-ресторан известной сети Califorian,</div>
<div>-СПА центр,</div>
<div>-тренажёрный зал,</div>
<div>-сауна,</div>
<div>-салон красоты,</div>
<div>-массажный салон,</div>
<div>-спортивные площадки.</div>
<br />
<div>Из каждой квартиры открывается шикарный вид на горы и море.</div>
<br />
<div>100% оплата.</div>
<br />
<div>Дата окончания строительства: 2021 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
