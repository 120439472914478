import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import pngFile1 from "../Home/1.png";
import pngFile2 from "../Home/2.png";
import pngFile3 from "../Home/3.png";
import pngFile4 from "../Home/4.png";
import pngFile5 from "../Home/5.png";
import pngFile6 from "../Home/6.png";
import pngFile7 from "../Home/7.png";
import pngFile8 from "../Home/8.png";
import pngFile9 from "../Home/9.png";
import pngFile10 from "../Home/10.png";
import pngFile11 from "../Home/11.png";
import pngFile12 from "../Home/12.png";
import pngFile13 from "../Home/13.png";
import pngFile14 from "../Home/14.png";
import pngFile15 from "../Home/15.png";
import pngFile16 from "../Home/16.png";
import pngFile17 from "../Home/17.png";
import pngFile18 from "../Home/18.png";

function Techstack() {
  return (
    <Row
      style={{
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Col
        xs={3}
        md={1}
        className="tech-icons"
        style={{ backgroundColor: "white" }}
      >
        <Image src={pngFile1} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile2} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile3} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile4} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile5} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile6} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile7} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile8} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile9} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile10} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile11} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile12} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile13} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile14} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile15} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile16} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile17} alt="Custom Image" fluid />
      </Col>
      <Col xs={3} md={1} className="tech-icons">
        <Image src={pngFile18} alt="Custom Image" fluid />
      </Col>
    </Row>
  );
}

export default Techstack;
