import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Panorama/1v1.jpg";
import pngFile2 from "./Panorama/2v1.jpg";
import pngFile3 from "./Panorama/3v1.jpg";
import pngFile4 from "./Panorama/4v1.jpg";
import pngFile5 from "./Panorama/5v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Panorama</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Panorama - новый комплекс в самом сердце района Искеле в 150 метрах от пляжа Лонг Бич. Проект включает два 12-этажных блока, состоящих из 111 роскошных апартаментов: студий, 1+1, 2+1, 3+1 и дуплексов 4+1, каждый из которых имеет уникальную конфигурацию благодаря архитектурному дизайну здания.</div> 
        <br />
        <div>Помимо уже привычной чистовой отделки WHITE BOX, клиентам предоставляется дополнительный пакет функциональных решений. В апартаментах продумано все до мельчайших деталей:</div>
        <br />
        <div>высококачественная кухня с кварцевыми рабочими поверхностями (Belenco), 
центральное и дополнительное потолочное освещение (Jupiter), 
теплые полы в санузле, 
стеклянная траповая душевая кабина, 
душевой гарнитур с тропическим душем (Hansgrohe), 
раковина-консоль и туалет инсталляция (Bocci), 
душевая ниша, 
держатели для полотенец и хромированный полотенцесушитель, 
система вентиляции с датчиками контроля влажности. </div>
        <br />
        <div>Апартаменты оборудованы канальной системой кондиционирования и бытовой техникой от ведущих производителей (BOCSH/Samsung).</div>
        <br />
        <div>Смотровая верхнего этажа с infinity-бассейном открывает с одной стороны завораживающий вид на залив Фамагуста, с другой – на панораму самого живописного региона Карпасского полуострова.</div>
        <br />
        <div>Инфраструктура комплекса:</div>
        <br />
        <div>-генератор,</div>
        <div>-детектор дыма и пожара,</div>
        <div>-круглосуточная охрана,</div>
        <div>-прачечная,</div>
        <div>-химчистка,</div>
        <div>-парковка и дорожки для инвалидов,</div>
        <div>-СПА-центр,</div>
        <div>-турецкие бани,</div>
        <div>-бассейн,</div>
        <div>-спортзал,</div>
        <div>-парковка,</div>
        <div>-детский бассейн,</div>
        <div>-ресепшн.</div>
        <br />
        <div>Есть возможность рассрочки на 12 месяцев.</div>
        <br />
        <div>Дата завершения проекта - август 2023г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
