import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Dolce Vita/1v1.jpg";
import pngFile2 from "./Dolce Vita/2v1.jpg";
import pngFile3 from "./Dolce Vita/3v1.jpg";
import pngFile4 from "./Dolce Vita/4v1.jpg";
import pngFile5 from "./Dolce Vita/5v1.jpg";
import pngFile6 from "./Dolce Vita/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Dolce Vita</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Масштабный малоэтажный комплекс бизнес класса на 408 юнитов "Dolce Vita" находится вблизи пляжа Лонг Бич и предлагает своим жителям территорию для жизни с максимальной внутренней инфраструктурой.</div> 
        <br />
        <div>Комплекс будет расположен в центре природы вдоль набережной в районе Искеле. Площадь территории 70.000 м2, что включает в себя всю инфраструктуру и 23 блока, из них 408 жилых объектов и 8 коммерческих.</div>
        <br />
        <div>Вы получаете возможность жить в одном из самых популярных районов острова - Искеле, и в то же время будете находиться в окружении леса, зелени и бескрайнего моря.</div>
        <br />
        <div>Вы достойны этой сладкой жизни со всеми привилегиями.</div>
        <br />
        <div>Жителям будут доступны такие удобства, как:</div>
        <br />
        <div>-тренажерный зал,</div>
        <div>-СПА-центр,</div>
        <div>-игровой клуб,</div>
        <div>-витаминный бар,</div>
        <div>-ресторан и кафе-бар,</div>
        <div>-офис управления,</div>
        <div>-бассейн,</div>
        <div>-пешеходная дорожка,</div>
        <div>-зеленые зоны,</div>
        <div>-спортивные площадки.</div>
        <br />
        <div>В комплексе будут:</div>
        <div>-джакузи,</div>
        <div>-видеонаблюдение,</div>
        <div>-центральный генератор,</div>
        <div>-автоматическая входная дверь,</div>
        <div>-центральная система очистки,</div>
        <div>-центральный спутник,</div>
        <div>-охрана.</div>
        <br />
        <div>Типы планировок:</div>
        <br />
        <div>-студии с террасой или садом площадью до 76 м2,</div>
        <div>-1+1 с террасой или садом площадью до 94 м2,</div>
        <div>-2+1 с террасой или садом площадью до 117 м2,</div>
        <div>-3+1 с террасой или садом площадью до 172м2.</div>
        <br />
        <div>Песчаный пляж находится в 200 метрах от комплекса.</div>
        <br />
        <div>План оплаты:</div>
        <br />
        <div>-5% от стоимости депозит,</div>
        <div>-35% первоначальный взнос,</div>
        <div>-30% - оплата в течение 24 месяцев до сдачи объекта,</div>
        <div>-30% - оплата при получении ключей. </div>
        <br />
        <div>Срок сдачи: окончание строительства первого этапа - июнь 2026 год, окончание строительства второго этапа - декабрь 2026 год.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
