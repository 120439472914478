import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/test3.gif";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 data-aos="fade-right">
              <span className="primary-header"> О нас </span>
            </h1>
            <p className="home-about-body" data-aos="fade-up">
              <div>
                Компания «White Estate» — лидер рынка недвижимости Северного
                Кипра. Направления деятельности нашей фирмы — недвижимость и
                консультации на Северном Кипре для русскоязычных и иностранных
                клиентов.
              </div>
              <br />
              <div>
                White Estate является официальной компанией и зарегистрирована
                на территории Турецкой Республики Северного Кипра. Юридическое
                наименование компании Hres Investment LTD.
              </div>
              <br />
              <div>
                Мы работаем для вас с душой, совестью и солнцем! C уважением,
                компания White Estate.
              </div>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <img data-aos="fade-left" src={myImg} className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
