import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCards";

import Education from "../../Assets/Projects/Education.png";
import Passport from "../../Assets/Projects/Passport.png";
import Relocation from "../../Assets/Projects/Relocation.png";
import Lawyer from "../../Assets/Projects/Lawyer.png";
import Dollar from "../../Assets/Projects/Dollar.png";
import Eye from "../../Assets/Projects/Eye.png";
import Pen from "../../Assets/Projects/Pen.png";
import Med from "../../Assets/Projects/Med.png";
import Buy from "../../Assets/Projects/Buy.png";

import { 
  BsWhatsapp,
  BsTelephone,
  BsInstagram
 } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { FaRegPaperPlane } from "react-icons/fa";


import "./project.css";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
      <h1
              style={{ fontSize: "2.1em", paddingBottom: "20px" }}
              data-aos="fade-right"
            >
              <span className="primary-header">Услуги</span>
            </h1>
        <p>Мы предлагаем следующие услуги</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Buy}
              title="Покупка недвижимости"
              description="Наши цены на недвижимость, строительство и услуги выгодно отличаются от тех, что на
              сегодняшний день предлагаются на Северном Кипре. При этом качество и сроки
              выполнения заказов остаются очень высокими, в чем вы можете сами легко убедиться,
              сравнив нас с любой другой компанией на острове.
              White Estate является официальной компанией и зарегистрирована на территории
              Турецкой Республики Северного Кипра."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Passport}
              title="Вид на жительство"
              description="Консультируем по вопросам оформления вида на жительства, гражданства и помогаем в
              оформлении при покупке недвижимости. Срок изготовления ВНЖ через нашу компанию
              составляет 14-20 дней. Для трудоустройства или ведения
              бизнеса необходимо специальное разрешение.Также мы открываем банковский счет в
              течение одного дня и помогаем в оформлении гражданства не только кипрского, но и
              других стран."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Eye}
              title="Смотровой тур"
              description="Бронирование отеля или апартаментов, трансфер из аэропорта, экскурсии по острову,
              посещение ликвидных объектов недвижимости и ведущих застройщиков. Наша компания
              предоставляет бесплатный смотровой тур на 3-6 дней. Мы организуем презентации
              популярных жилых комплексов Северного Кипра, выезжаем на объекты и стройку для
              того, чтобы вы лично ознакомились с качеством строительных материалов, встречаемся с
              застройщиками и приятно проводим время."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Lawyer}
              title="Юридическая проверка недвижимости"
              description="Юридическая проверка недвижимости (кому она принадлежит, не находится ли в залоге,
                является ли законным данное строительство). Полноценное юридическое сопровождение
                покупки недвижимости на Кипре рекомендуется осуществлять при помощи
                профессионального лицензированного юриста. В этом случае ваши права будут четко
                гарантированы кипрским сводом соответствующих законов."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Relocation}
              title="Релокация бизнеса"
              description="Помогаем иностранным физическим и юридическим лицам в открытии и релокации
              бизнеса и сотрудников. Данная услуга решает все вопросы, связанные с переездом в
              другую страну, перезапуском или открытием нового бизнеса и адаптацией на новом месте. Релокация бизнеса на Северный Кипр - одно из востребованных направлений для всех тех,
              кто желает развивать свой бизнес без ограничений и направляет свои усилия на то, чтобы
              создать международный бренд."
            />
          </Col>
           
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Dollar}
              title="Аренда"
              description="Подбираем апартаменты в аренду для вас и вашей семьи, а также помогаем взять в прокат
              транспортное средство. Если вы купили недвижимость для дальнейшей сдачи в аренды,
              мы берем на себя обязанности по поиску клиентов, размещению рекламы, их заселению,
              проверке инвентаря и предоставлении полного отчета о состоянии."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Pen}
              title="Дизайн интерьеров"
              description="Услуги дизайна интерьера и реализация элитных дизайн-проектов на выгодных условиях. Вы задумались об изменении интерьера, но перспективы разъездов по магазинам в
              поисках материалов и мебели, необходимость тотального контроля строительной бригады
              и неопределенность сроков пугают вас? В таком случае вам поможет услуга разработки
              дизайн-проекта квартиры, дома или коммерческой недвижимости."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Med}
              title="Медицинский туризм"
              description="Европейское медицинское обслуживание и высокий уровень сервиса доступны каждому.
              Сюда приезжают люди из стран Турции, Германии, СНГ, Литвы, Латвии, Эстонии, Румынии,
              Болгарии.
              Самыми популярными медицинскими услугами на Кипре в настоящее время являются
              следующие: эстетическая хирургия, гинекология и акушерство, услуги травматолога и
              ортопедические процедуры, общая диагностика состояния организма, а также
              репродуктивные технологии."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Education}
              title="Образование"
              description="Обеспечение студентов всей необходимой поддержкой с момента выбора программы
              обучения и университета. Образование на Северном Кипре - востребованная и популярная услуга. Современные
              районы, новые университеты, доступные цены на обучение, высокое качество
              образования, которое ценится в Европе, – и все это в потрясающем климате. "
            />
          </Col>

        </Row>
      </Container>
      <Row>
          <Col md={12} className="home-about-social" style={{ paddingTop: 50, paddingBottom: 50 }}>
            <h1 data-aos="fade-right">
              <span className="primary-header">Наши контакты</span>
            </h1>
            <p data-aos="fade-left">свяжитесь с нами</p>
            <ul className="home-about-social-links" data-aos="fade-up">
              <li className="social-icons">
                <a
                  href="https://t.me/whiteagencyestate"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane/>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="mail"
                >
                  <AiOutlineMail />
                </a>
              </li>
              {/* <li className="social-icons">
                <a
                  href="https://www.youtube.com/@white_estate"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="youtube"
                >
                  <BsPlayBtnFill />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/whiteestate_agency/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="instagram"
                >
                  <BsInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="phone"
                >
                  <BsTelephone />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      {/* <ScrollToTop /> */}
    </Container>
  );
}

export default Projects;
