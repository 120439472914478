import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Natulux/1v1.jpg";
import pngFile2 from "./Natulux/2v1.jpg";
import pngFile3 from "./Natulux/3v1.jpg";
import pngFile4 from "./Natulux/4v1.jpg";
import pngFile5 from "./Natulux/5v1.jpg";
import pngFile6 from "./Natulux/6v1.jpg";

import "./ImageModal.css";

function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Natulux</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Комплекс премиум класса Natulux - это идеальное место для роскошной жизни и инвестиционных возможностей на Северном Кипре. Он расположен в живописном районе Татлысу, откуда открывается захватывающий вид на Средиземное море и пышную зелень гор региона.</div>
        <br />
        <div>Раскинувшись на первой береговой линии, комплекс предлагает оазис спокойствия и комфорта, где вы можете насладиться теплым солнцем и морским бризом. Рядом находится полуостров Карпас, знаменитый Черепаший пляж Alagadi Turtle Beach.</div>
        <br />
        <div>Natulux расположен в быстро развивающемся районе Кучук Еренкой, который является маленьким и спокойным регионом, который привлекает туристов и покупателей недвижимости своими живописными видами, близостью к морю и относительной удаленностью от городской суеты.  Как и в большинстве мест на Северном Кипре, в Кучук Еренкой присутствует смесь традиционного кипрского колорита и современных удобств, благодаря развивающемуся сектору недвижимости и увеличению числа жилых комплексов, таких как Natulux, предлагающих комфортное жильё с различными удобствами и красивыми видами. Такие развития делают этот регион привлекательным для иностранных инвесторов и тех, кто ищет дом вдали от городской жизни.</div>
        <br />
        <div>Жители Natulux смогут наслаждаться широким спектром удобств и развлечений. В комплексе есть:</div>
        <br />
        <div>-бассейн и крытый бассейн,</div>
        <div>-мини-гольф,</div>
        <div>-кинотеатр на открытом воздухе,</div>
        <div>-СПА,</div>
        <div>-спортзал,</div>
        <div>-зона для барбекю.</div>
        <br />
        <div>План оплаты:</div>
        <br />
        <div>-5% депозит от стоимости недвижимости,</div>
        <div>-35% первоначальный взнос,</div>
        <div>-60% - беспроцентная рассрочка до конца строительства.</div>
        <br />
        <div>Срок сдачи: июнь 2026 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
