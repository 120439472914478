import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Недвижимость на Северном Кипре",
          "Услуги на Северном Кипре",
          "Ваши друзья на Северном Кипре",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 10,
      }}
    />
  );
}

export default Type;
