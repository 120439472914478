import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Salamis Holiday Homes/1v1.jpg";
import pngFile2 from "./Salamis Holiday Homes/2v1.jpg";
import pngFile3 from "./Salamis Holiday Homes/3v1.jpg";
import pngFile4 from "./Salamis Holiday Homes/4v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Salamis Holiday Homes</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Жилой комплекс на 144 юнита Salamis Holiday Homes расположен в в пешей доступности от начальной школы. Для любителей античности рядом находится древний город Саламис, который является объектом культурного наследия. Напротив Парк Йенибоазичи, который имеет прогулочную зону, кафе и рестораны, игровые площадки и площадки для детей разных возрастных групп.</div> 
        <br />
        <div>Salamis находится в живописном уголке Йенибоазичи, в окружении моря, леса и парка. Один из главных плюсов Salamis Holiday Homes — его близкое расположение к золотым пляжам и историческим достопримечательностям древнего города Саламис. Это идеальное место для тех, кто ищет сочетание отдыха и приключений, где каждый день обещает новое открытие. Также рядом находится колледж, школа и детский сад Near East.</div>
        <br />
        <div>Комплекс Salamis Holiday Homes предлагает разнообразное жилье, начиная от студий на первом этаже с просторными частными садами, отличными для обедов на свежем воздухе, и заканчивая пентхаусами с панорамными террасами. Каждая квартира спроектирована с учетом максимального использования пространства и естественного света, обеспечивая уединение и комфорт.</div>
        <br />
        <div>До моря - 800 метров.</div>
        <br />
        <div>Комплекс охватывает обширную территорию в 21 000 м2 и включает в себя:</div>
        <div>-ресторан,</div>
        <div>-кафе-бар,</div>
        <div>-бассейны,</div>
        <div>-спортивные площадки,</div>
        <div>-бассейн для детей,</div>
        <div>-зеленые зоны,</div>
        <div>-пешеходные тропы,</div>
        <div>-парковку,</div>
        <div>-центральный генератор,</div>
        <div>-система видеонаблюдения.</div>
        <br />
        <div>Своя управляющая компания обеспечивает контроль над сохранностью актива в период сдачи недвижимости в аренду и предоставляют отчеты о загрузке.</div>
        <br />
        <div>Инвестор получит гарантию в качестве аренды недвижимости на 2 года. То есть, если вы приобретаете квартиру, то можете сдать ее застройщику и получать пассивный доход на протяжении двух лет. Еще один большой плюс при покупки жилья — это полностью меблированная квартира, а также бытовая техника идет в подарок от застройщика.</div>
        <br />
        <div>План оплаты:</div>
        <br />
        <div>-5000 фунтов - депозит,</div>
        <div>-10% - первоначальный взнос,</div>
        <div>-10% - оплата через 3 месяца,</div>
        <div>-10% - оплата через 6 месяцев,</div>
        <div>-30% - оплата на 17 месяцев от даты внесения 3-го платежа,</div>
        <div>-35% - остаток на 12 месяцев по беспроцентной рассрочке или на 120 месяцев при использовании кредита под %. </div>
        <br />
        <div>Передача ключей возможна после полной оплаты!</div>
        <br />
        <div>Срок сдачи: июль 2025 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
