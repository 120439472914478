import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Sapphire/1v1.jpg";
import pngFile2 from "./Sapphire/2v1.jpg";
import pngFile3 from "./Sapphire/3v1.jpg";
import pngFile4 from "./Sapphire/4v1.jpg";
import pngFile5 from "./Sapphire/5v1.jpg";
import pngFile6 from "./Sapphire/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Sapphire Blue</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Масштабный комплекс бизнес класса на 2 345 юнитов Grand Sapphire Resort Blu возводится напротив знаменито пляжа Long Beach (Лонг Бич) и Pera Beach Club. Вдоль пляжа предусмотрена дорожка для любителей прогулок, бега или катания на велосипеде. Для детей разных возрастных групп есть детские площадки и луна-парк.</div> 
        <br />
        <div>Вся инфраструктура необходимая для комфортной жизни и отдыха находится в пешей доступности.</div>
        <br />
        <div>В комплексе для жителей предусмотрено:</div>
        <br />
        <div>-Infinity бассейн на крыше каждого многоэтажного блока,</div>
        <div>-крытый бассейн с подогревом, песчаный бассейн, бассейн «Punta Cana» 4600м2 и аквапарк,</div>
        <div>-тренажерный зал Fit Plus,</div>
        <div>-бизнес-центр,</div>
        <div>-ресторан, бар и кафе,</div>
        <div>-теннисный корт и спортивные площадки,</div>
        <div>-большая детская площадка,</div>
        <div>-супермаркеты,</div>
        <div>-детский клуб,</div>
        <div>-более 10 бассейнов на территории, один из которых САМЫЙ большой на ВСЕМ острове!</div>
        <div>-кинотеатр под открытым небом,</div>
        <div>-салон красоты,</div>
        <div>-SPA салон,</div>
        <div>-зона для выгула питомцев,</div>
        <div>-трех уровневая парковка: подземная, на земле и над землей,</div>
        <div>-шаттл сервис,</div>
        <div>-5 звездочный отель с казино.</div>
        <br />
        <div>Плюсы при покупке недвижимости в Grand Sapphire BLU:</div>
        <br />
        <div>Инвестор получит гарантию в качестве аренды недвижимости на 2 года. То есть, если вы приобретаете квартиру, то можете сдать ее застройщику и получать пассивный доход на протяжении двух лет. Еще один большой плюс при покупки жилья — это полностью меблированная квартира, а также бытовая техника идет в подарок от застройщика.</div>
        <br />
        <div>План оплаты:</div>
        <br />
        <div>-депозит 5% от стоимости недвижимости,</div>
        <div>-30% первоначальный взнос в течение месяца,</div>
        <div>-остаток 65% без % рассрочка до сентября 2027 г.</div>
        <br />
        <div>Срок сдачи: сентябрь 2027 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
