import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Four Seasons Life 1/1v1.jpg";
import pngFile2 from "./Four Seasons Life 1/2v1.jpg";
import pngFile3 from "./Four Seasons Life 1/3v1.jpg";
import pngFile4 from "./Four Seasons Life 1/4v1.jpg";
import pngFile5 from "./Four Seasons Life 1/5v1.jpg";
import pngFile6 from "./Four Seasons Life 1/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Four Seasons Life 1</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Four Seasons Life новый жилой комплекс современных, красивых апартаментов и вилл, прямо на прекрасном песчаном пляже в регионе Боаз, в 15 -ти минутах на машине до исторического города Фамагуста. Жилой комплекс построен на 102,000 квадратных метрах земли, Из которых 65,000 м2 зеленые зоны, включающие в себя 392 жилых дома и 28 коммерческих единиц. Four Seasons Life предлагает различные виды размещения: студии, 1- или 2-х комнатные квартиры и 2- или 3-х комнатные дуплексы, виллы.</div> 
        <br />
        <div>Район Боаз очень привлекателен для инвестирования, цены на землю и объекты недвижимости стремительно растут. Комплекс размещен на просторной зелёной облагороженной территории с ландшафтным дизайном на первой береговой линии. В центре находится бассейн и зона отдыха.</div>
        <br />
        <div>Инфраструктура:</div>
        <br />
        <div>-детская игровая площадка,</div>
        <div>-СПА и тренажерный зал,</div>
        <div>-велосипедная дорожка,</div>
        <div>-ресторан, закусочная,</div>
        <div>-пляжный бар,</div>
        <div>-аква-парк,</div>
        <div>-прогулочная зона,</div>
        <div>-круглосуточная охрана и видеонаблюдение,</div>
        <div>-генератор,</div>
        <div>-магазины.</div>
        <br />
        <div>FOUR SEASONS LIFE - это комфортная, безопасная и роскошная жизнь в окружении природы. </div>
        <br />
        <div>100% оплата.</div>
        <br />
        <div>Дата окончания строительства: декабрь 2021 г. </div>
        </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
