import React, { useState } from "react";
import { Modal, Image } from "react-bootstrap";
import openImageIcon from "./YouTube1.png";

function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a onClick={handleShow}>
        <Image
          src={openImageIcon}
          fluid
          alt="Open Image"
          style={{ cursor: "pointer" }}
        />
      </a>

      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop: "5%" }}>
        <iframe
          width="100%"
          height="450"
          src="https://www.youtube.com/embed/f9WoUsFYbUI?si=GDsDf_qee1zBcwmh"
          title="White Estate"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Modal>
    </>
  );
}

export default ImageModal;
