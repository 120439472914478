import React from "react";
import Card from "react-bootstrap/Card"

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title style={{ paddingBottom:"35px", fontSize:"25px" }}>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "left", paddingBottom:"25px", padding:"15px"}}>
          {props.description}
        </Card.Text>
      </Card.Body>
    </Card>
    
  );
}
export default ProjectCards;
