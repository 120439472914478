import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./La Casalia/1v1.jpg";
import pngFile2 from "./La Casalia/2v1.jpg";
import pngFile3 from "./La Casalia/3v1.jpg";
import pngFile4 from "./La Casalia/4v1.jpg";
import pngFile5 from "./La Casalia/5v1.jpg";
import pngFile6 from "./La Casalia/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  ПОДРОБНЕЕ
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>La Casalia</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Эксклюзивный комплекс La Casalia будет находится на 260 донюмах земли (340 000 м2). Состоит из 2 фаз:</div> 
        <br />
        <div>-1 фаза - с морским видом;</div>
        <div>-2 фаза - лес и горы.</div>
        <br />
        <div>Проект включает в себя 420 юнитов, где 40% застройки, а 60% будут отданы под прекрасную инфраструктуру: собственная марина, лес, прекрасный пляж.</div>
        <br />
        <div>Комплекс будет находится в Эсентепе, на первой береговой линии. Песчаный пляж, собственная марина, огромная территория зеленой зоны, лес - всё это делает расположение комплекса привлекательным не только для тех, кто ищет инвестиционную возможность, но и для тех, кто хочет реализовать свою мечту - домик у моря.</div>
        <br />
        <div>Инфраструктура:</div>
        <br />
        <div>-своя марина, в которой будут предусмотрены рейсовые катеры от Эсентапе до Кирении,</div>
        <div>-электроскутеры на всей территории, которыми могут пользоваться все резиденты комплекса,</div>
        <div>-сад или место под посадку деревьев, растений - по вашему желанию,</div>
        <div>-парковка: подземная, наземная,</div>
        <div>-8 км зеленой зоны (лес, дорожки),</div>
        <div>-собственный пляж,</div>
        <div>-своя винодельня,</div>
        <div>-тренажерный зал,</div>
        <div>-СПА,</div>
        <div>-бизнес-центр,</div>
        <div>-рестораны, бары,</div>
        <div>-бассейны,</div>
        <div>-детские площадки,</div>
        <div>-спортивные площадки.</div>
        <br />
        <div>План оплаты:</div>
        <br />
        <div>1. Размер депозита варьируется в зависимости от типа и составляет минимум:</div>
        <br />
        <div>-5 000 £-€-$ для STD, 1+1 и 2+1 Apartment,</div>
        <div>-10 000 £-€-$ для вилл 2+1,</div>
        <div>-20 000 £-€-$ для вилл 3+1.</div>
        <br />
        <div>С 20.11.2023г. срок внесения депозита при индивидуальной продаже составит 1 МЕСЯЦ.</div>
        <br />
        <div>2. Первоначальный взнос 35%:</div>
        <div>-при оплате 60% - предоставляется скидка 2%,</div>
        <div>-при оплате 80% - предоставляется скидка 3%,</div>
        <div>-при оплате 100% - предоставляется скидка 4%.</div>
        <br />
        <div>3. Оставшаяся сумма до декабря 2027 года</div>
        <br />
        <div>Срок сдачи: декабрь 2027 г.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
