import React from 'react';
import './LEDSign.css';

const LEDSign = () => {
  return (
    <div className="app-container">
      <div id="led-sign">
        <div id="led-text">WHITE ESTATE | EXCLUSIVE OFFERS | WHITE ESTATE | EXCLUSIVE OFFERS |</div>
      </div>
    </div>
  );
};

export default LEDSign;